export default {
  heading: 'Merriweather, serif',
  body: 'Open Sans, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Playfair Display, serif',
  googleFonts: [
    'Open Sans:100,200,300,400,500,600,700,800,900',
    'Merriweather:300,400,500,600,700,800,900',
  ],
  // customFamilies: [''],
  // customUrls: [''],
};
