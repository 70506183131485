export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      background: 'unset',
      backgroundColor: 'white',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      padding: '0rem',
      // padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1001',
    },

    '.smallNavMenu': {
      border: 'none',
      '.navItem': {
        color: 'text',
        color: 'text',
        margin: '0rem',
        padding: ['0.75rem', '0.75rem', '1rem 1rem'],
        a: {
          fontSize: ['0.9rem', '0.9rem', '0.9rem', '0.9rem'],
        },
      },
    },

    '.hamburger': {
      padding: '0.75rem',
      height: '60px',
      width: '60px',
      '> div': {
        backgroundColor: 'secondary',
      },
    },
    '.hamburgerOpen': {
      padding: '0.75rem',
      height: '60px',
      width: '60px',
      '> div': {
        backgroundColor: 'secondary',
        width: 'calc(100% - 1rem)',
      },
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      borderBottom: '1px solid',
      borderColor: 'secondary',
      margin: '0rem',
      img: {
        maxHeight: ['55px', '', '100px', ''],
      },
    },
    '.navMenuOpen': {
      backgroundColor: 'white',
      width: ['90%', '', '40%'],
      justifyContent: ['flex-start', 'flex-start', 'flex-start', 'center'],
      // padding: ['2rem 0rem 0rem', '2rem 0rem 0rem', '4rem 0rem 0rem'],
      padding: '0rem',
      borderTop: '10px solid',
      borderColor: 'secondary',
      borderLeft: '1px solid lightgrey',
      '.navItemDropdown': {
        width: '100%',
        flexGrow: '1',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        display: 'flex',
        flexDirection: 'column',
        '> .navItem': {
          height: '100%',
        },
      },
      '> li:nth-of-type(1)': {
        borderTop: 'solid 1px lightgrey',
      },
      '.seperatorLine': {
        width: '100%',
        maxWidth: 'unset',
        borderColor: 'secondary',
        margin: '1rem 0rem 0rem',
      },
      '.navItem': {
        padding: '0rem',
        borderBottom: '1px solid lightgrey',
        margin: '0rem',
        width: '100%',
        flexGrow: '1',
        a: {
          display: 'flex',
          height: '100%',
          // justifyContent:'center',
          alignItems: 'center',
          padding: ['0.75rem', '0.75rem', '1rem', '2rem'],
          fontSize: ['1rem', '1.25rem', '1.5rem', '1.75rem'],
          color: 'grey',
          ':hover': {
            backgroundColor: '#b8b08b42',
          },
        },
      },
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)',
    },

    '.navItemDropdownMenu': {
      backgroundColor: 'background',
    },

    '.socialIconsContainer, .phoneContainer': {
      '.socialContainer': {
        padding: '0rem',
      },
      margin: '0rem',
      padding: ['1rem', '', '1.5rem'],
      backgroundColor: 'secondary',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'text',
      border: 'none',
      width: '100%',
      fontSize: '1rem',
      maxWidth: 'unset',
      svg: {
        path: {
          fill: 'text',
        },
      },
    },
    '.phoneContainer': {
      marginTop: 'auto',
    },

    '.containerScrolled': {
      background: 'none',
      backgroundColor: 'white',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      padding: '0.5rem',
      zIndex: '1001',
      '.hamburger > div': {
        backgroundColor: '#c6a34d',
      },
    },
    '.logo': {
      position: 'static',
      transform: 'unset',
      padding: '1rem',
      zIndex: '0',
      img: {
        opacity: '1',
        maxHeight: ['65px', '65px'],
      },
    },
    '.logoScrolled': {
      img: {
        opacity: '1',
        maxHeight: ['55px', '55px'],
      },
    },
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'white',
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'grey',
        },
      },
    },

    '.sectionHeading': {
      color: 'secondary',
      fontWeight: '500',
      borderColor: 'secondary',
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0) invert(0.6)',
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black',
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'center'],
    },
  },

  ctaWidget: {
    display: 'none',
    a: {
      backgroundColor: 'secondary',
    },
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    width: 'fit-content',
    borderBottom: '2px solid',
    borderColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '2.5rem',
    // order: '2',
    color: 'primary',
    fontWeight: '500',
    whiteSpace: 'pre-wrap',
    marginBottom: '1.5rem',
  },

  subtitle: {
    color: 'grey',
    fontWeight: 'bold',
    textTransform: 'initial',
    // order: '1',
    fontFamily: 'body',
    fontSize: '1.25rem',
    textTransform: 'uppercase',
  },

  text: {
    // order: '3',
  },

  sideBySide1: {
    padding: '1rem 1rem 0rem',
    '.content': {
      width: ['', '', '50%'],
      padding: ['2rem 1rem', '', '', '3rem'],
      border: 'solid 2px lightgrey',
      borderLeft: '5px solid #b8b08b',
      '.title': {
        variant: 'customVariants.title',
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
      },
      '.text': {
        variant: 'customVariants.text',
      },
      a: {
        order: '4',
      },
    },
    '.lazyload-wrapper': {
      width: ['', '', '50%'],
      margin: ['', '', '0rem 0rem 0rem 1rem'],
      img: {
        // height: '100vh',
      },
    },
    '.ctaButton': {
      variant: 'buttons.secondary',
    },
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',

    '.content': {
      width: ['', '', '50%'],
      padding: ['2rem 1rem', '', '', '3rem'],
      border: 'solid 2px lightgrey',
      order: ['', '', '2'],
      borderLeft: '5px solid #b8b08b',
      '.title': {
        variant: 'customVariants.title',
      },
      '.subtitle': {
        variant: 'customVariants.subtitle',
      },
      '.text': {
        variant: 'customVariants.text',
      },
      a: {
        order: '4',
      },
    },
    '.lazyload-wrapper': {
      width: ['', '', '50%'],
      margin: ['', '', '0rem 1rem 0rem 0rem'],

      img: {
        // height: '100vh',
      },
    },
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.hero_content_container': {
      display: 'none',
    },
    '.section': {
      border: 'none',
      width: ['100%', '', '65%'],
      maxWidth: 'unset',
      margin: '0rem',
      height: 'auto',
    },
    '.title': {
      border: 'none',
      padding: '0rem',
      fontSize: ['2rem', '', '3.5rem'],
    },
    '.subtitle': {},
    '.text': {
      color: 'white',
    },
    '.ctaButton': {
      variant: 'buttons.primary',
    },
  },
  homepageHeroShout: {
    display: 'none',
  },

  homepageShout: {
    zIndex: ['1'],
    // maxHeight: ['', '', '50vh'],
    margin: '1rem',
    width: 'calc(100% - 2rem)',
    '.imageContainer': {
      width: ['', '', '20%'],
      order: ['2', ''],
      img: {
        boxShadow: '2px 4px 13px #8a8a8a',
      },
    },
    '.content': {
      padding: '2rem',
      alignItems: 'flex-start',
      textAlign: 'left',
      order: ['1', ''],
      backgroundColor: '#271c1c',
      border: 'solid 2px lightgrey',
      borderLeft: '8px solid #b8b08b',
      // borderColor: 'secondary',
      width: ['', '', '80%'],
      boxShadow: '2px 4px 13px #8a8a8a',
    },
    '.title': {
      variant: 'customVariants.title',
      textAlign: 'left',
      margin: '0rem 0rem 1rem',
      color: 'white',
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      textAlign: 'left',
      margin: '1rem 0rem',
    },
    '.text': {
      variant: 'customVariants.text',
      textAlign: 'left',
      margin: '1rem 0rem 1rem',
      maxWidth: 'unset',
      color: 'white',
    },
    '.date': {
      textAlign: 'left',
      margin: '1rem 0rem 2rem',
      color: 'secondary',
      order: '2',
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
      },
    },
  },

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2',
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',
  },

  homepageEvents: {
    variant: 'customVariants.sideBySide2',
  },

  homepageHours: {
    variant: 'customVariants.sideBySide1',
  },

  // homepageSlider: {
  //   padding: '0rem',
  //   '.slick-dots': {
  //     display: 'none !important',
  //   },
  // },
  homepageTestimonials: {
    padding: ['2rem 1rem', '', '4rem 1rem'],
    maxWidth: 'unset',
    '.menuSectionTitle': {
      variant: 'customVariants.title',
      border: 'none',
      textAlign: 'center',
      margin: '0 auto 1rem',
    },

    '.menuSectionDescription': {
      variant: 'customVariants.text',
      fontSize: '1rem',
    },

    '.menuItemNameContainer': {
      variant: 'customVariants.subtitle',
      order: '4',
      margin: '1rem auto 0rem',
      textAlign: 'center',
    },

    '.menuItemName': {
      fontSize: ['1rem', '1rem', '1rem', '1rem'],
    },

    '.menuItemDescription': {
      variant: 'customVariants.text',
      color: 'primary',
      fontSize: '1rem',
      textAlign: 'center',
      lineHeight: '1.5',
      width: '100%',
      padding: ['0rem', '', '0rem 1rem'],
      margin: '0 auto 1rem',
    },

    '.slick-slider': {
      maxWidth: '767px',
      margin: '0 auto',
    },
  },

  homepageBoxes: {
    padding: '0rem',
    marginTop: '1rem',

    '.box': {
      padding: '0rem',
      width: ['100%', '', '50%', '33.3%'],
      position: 'relative',
      height: '450px',
      background: 'transparent',
      color: 'white',
      margin: '0rem',
      borderRadius: '0px',
      overflow: 'hidden',
      flexGrow: '1',
      ':hover': {
        img: {
          transform: 'scale(1.2)',
          filter: 'brightness(0.8)',
        },
      },
    },
    '.image': {
      position: 'absolute',
      height: '450px',
      zIndex: '-1',
      objectFit: 'cover',
      filter: 'brightness(0.55)',
      transition: 'all ease-in-out 0.8s',
    },
    '.title': {
      padding: '0rem 2rem',
      order: '2',
      textTransform: 'uppercase',
      fontSize: ['1.5rem', '1.75rem', '2rem', '', '2.25rem'],
    },
    '.subtitle': {
      margin: '3rem 2rem 1rem',
      padding: '1rem',
      borderBottom: '2px solid white',
      width: 'calc(100% - 4rem)',
      textTransform: 'uppercase',
      order: '1',
      fontWeight: '400',
      color: 'secondary',
      backgroundColor: '#00000073',
    },
    '.text': {
      padding: '0rem 2rem',
      order: '3',
      color: 'white',
      margin: '1rem 0rem 2rem',
      p: {
        color: 'white',
      },
    },
    '.ctaLink': {
      variant: 'buttons.secondary',
      margin: '0rem 2rem 3rem',
      order: '4',
      borderColor: 'white',
      color: 'white',
      width: 'fit-content',
      textTransform: 'uppercase',
    },
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
    },
  },

  aboutpageTeam: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      width: ['', '', '55%'],
      '::before': {
        background: 'unset',
      },
      img: {
        height: '',
      },
    },
  },

  aboutpageHistory: {
    variant: 'customVariants.sideBySide2',
    // '.lazyload-wrapper': {
    //   order: '2',
    //   width: ['', '', '55%'],
    //   '::before': {
    //     background: 'unset',
    //   },
    //   img: {
    //     height: '',
    //   },
    // },
  },
  aboutpageCareer: {
    variant: 'customVariants.sideBySide1',
    // '.lazyload-wrapper': {
    //   width: ['', '', '55%'],
    //   '::before': {
    //     background: 'unset',
    //   },
    //   img: {
    //     height: '',
    //   },
    // },
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    border: 'solid 1px black',
    padding: ['1rem', '1rem'],
    margin: ['1rem 0.5rem', '', '', '', '1rem auto 3rem'],
    width: ['calc(100% - 1rem)'],
    backgroundColor: 'white',
    '.menuContainer': {
      paddingBottom: '1rem',
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem',
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7',
      },
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem',
    },
    '.menuSectionTitle': {
      textTransform: 'uppercase',
      color: 'primary',
      borderBottom: 'solid 2px',
      borderColor: 'secondary',
      margin: ['1rem', '', '1rem 1.5rem'],
      paddingBottom: '1rem',
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2rem'],
    },

    '.menuItemName': {
      color: '#235825',
      fontFamily: 'heading',
    },

    '.menuSectionDescription': {
      fontSize: '1.1rem',
      textAlign: 'left',
      margin: ['1rem', '', '1rem 1.5rem'],
    },
    '.menuItemDescription': {
      color: 'primary',
    },
    '.menuPriceContainer': {
      fontFamily: 'heading',
      // display: 'none',
    },
    '.variantsContainer': {
      // display: 'none',
    },
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      // display: 'none',
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black',
    },
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      },
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'black',
    },
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem',
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem',
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey',
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey',
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center',
      },
    },
  },

  locationMap: {
    order: '2',
    backgroundColor: '#c6a34d',
    color: 'white',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'white',
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0',
    },
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  eventsLeft: {
    variant: 'customVariants.sideBySide1',
  },
  eventsRight: {
    variant: 'customVariants.sideBySide2',
  },

  // ? ========================
  // ? ====  Reservations  ====
  // ? ========================

  reservations: {
    padding: ['2rem 1rem', '', '5rem 1rem'],
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {
    backgroundColor: 'background',
    padding: ['1rem', '1rem', '1rem', '1rem'],
    '.boxesHeading': {
      display: 'none',
    },
  },

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {},
}
