export default {
  primary: {
    color: 'light',
    border: 'solid 1px',
    fontSize: '0.85rem',
    fontWeight: '700',
    textTransform: 'uppercase',
    paddingBottom: '0rem',
    padding: '1rem 3rem',
    borderColor: 'lightgrey',
    backgroundColor: 'secondary',
    ':hover': {
      backgroundColor: 'dark',
      color: 'light',
    },
  },
  secondary: {
    padding: '1rem 2rem',
    color: 'white',
    border: 'none',
    borderRadius: '2px',
    border: 'solid 1px',
    borderColor: 'primary',
    borderRadius: '0px',
    fontWeight: 'bold',
    display: 'flex',
    fontSize: '0.85rem',
    backgroundColor: 'black',
    ':hover': {
      // opacity: '0.4',
      backgroundColor: 'transparent',
      color: 'black',
    },
  },
};
